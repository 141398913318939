import React from 'react'

export default function LoadingView() {
  return (
    <div className="flex flex-col justify-center items-center flex-grow">
      <div className="flex flex-col justify-center items-center">
        <div className="loading loading-spinner loading-xl text-secondary"></div>
        <div className="text-lg mt-4">Loading...</div>
      </div>
    </div>
  )
}

import React, {useEffect, useRef} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faXmark, faLockOpen} from '@fortawesome/free-solid-svg-icons'
import FlexipassLogo from '../../assets/flexipass-logo.png'
import {Door} from '../../types'
import {useOpenDoorMutation} from '../../services/guestWebKeysApi'

export interface WebKeyModalProps {
  webKeyId: string
  door: Door
  isOpen: boolean
  onClose: () => void
}

enum ViewState {
  Opening = 'Opening',
  Open = 'Open',
  Error = 'Error'
}

export default function WebKeyModal({
  isOpen,
  onClose,
  door,
  webKeyId
}: WebKeyModalProps) {
  const [viewState, setViewState] = React.useState(ViewState.Opening)
  const modalRef = useRef<HTMLDialogElement>(null)
  const [openDoor] = useOpenDoorMutation()
  const doorId = door?.id

  useEffect(() => {
    const modalElement = modalRef.current
    if (modalElement) {
      if (isOpen) {
        modalElement.showModal()
      } else {
        modalElement.close()
      }
    }
  }, [modalRef, isOpen])

  useEffect(() => {
    if (doorId) {
      setViewState(ViewState.Opening)
      openDoor({webKeyId, doorId})
        .unwrap()
        .then(() => {
          setViewState(ViewState.Open)
        })
        .catch(() => {
          setViewState(ViewState.Error)
        })
    }
  }, [doorId])

  if (!door) {
    return ''
  }

  return (
    <dialog ref={modalRef} className="modal" onClose={() => onClose()}>
      <div className="modal-box bg-white p-0">
        <div className="p-8">
          <button
            className="btn btn-sm btn-circle btn-ghost border-0 absolute right-2 top-2"
            onClick={() => onClose()}
          >
            ✕
          </button>

          {viewState === ViewState.Opening ? (
            <div className="flex flex-col justify-center items-center">
              <div className="loading loading-spinner loading-xl text-secondary"></div>
              <div className="text-xl header mt-4">Now unlocking</div>
              <div className="text-xl header text-center">{door?.name}</div>
              <div className="mt-4 text-center w-4/5">
                It takes just a few seconds to connect to the door
              </div>
            </div>
          ) : (
            ''
          )}

          {viewState === ViewState.Open ? (
            <div className="flex flex-col justify-center items-center my-4">
              <div>
                <FontAwesomeIcon
                  icon={faLockOpen}
                  className="text-5xl text-secondary"
                />
              </div>
              <div className="text-xl header text-center mt-4">
                {door?.name}
              </div>
              <div className="text-xl header">Door unlocked!</div>
              <div className="mt-4 text-center w-4/5">
                This door will automatically lock behind you.
              </div>
            </div>
          ) : (
            ''
          )}

          {viewState === ViewState.Error ? (
            <div className="flex flex-col justify-center items-center my-4">
              <div className="bg-red-400 w-16 h-16 rounded-full flex justify-center items-center">
                <FontAwesomeIcon
                  icon={faXmark}
                  className="text-5xl text-black"
                />
              </div>
              <div className="text-xl header mt-8">Unable to Open</div>
              <div className="text-xl header text-center">{door.name}</div>
              <div className="mt-4 text-center w-4/5">
                We're sorry, we were unable to open the door. Please make sure
                you are connected to WiFi and try again. If the problem
                persists, please see the staff on the second floor.
              </div>
            </div>
          ) : (
            ''
          )}

          <div className="flex justify-center items-center mt-8">
            <img src={FlexipassLogo} className="w-4/5" />
          </div>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button onClick={() => onClose()}>close</button>
      </form>
    </dialog>
  )
}

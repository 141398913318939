import {
  Action,
  AnyAction,
  combineReducers,
  configureStore,
  Reducer,
  ThunkAction
} from '@reduxjs/toolkit'
import {getConfig} from '../services/config'
import {guestWebKeysApi} from '../services/guestWebKeysApi'
import {getLogger} from '../services/logging'

const {reduxDevTools} = getConfig()

const logger = getLogger('ReduxStore')

const combinedReducer = combineReducers({
  [guestWebKeysApi.reducerPath]: guestWebKeysApi.reducer
})

let rootState: RootState

const isSignOut = ({type, payload}: AnyAction) =>
  type === 'auth/setAuthenticated' && payload === false

const defaultRootState = {
  app: {
    isInitialized: true
  },
  user: {
    isAuthenticated: false
  }
} as RootState

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  // sign out
  if (isSignOut(action)) {
    state = defaultRootState
  }
  rootState = combinedReducer(state, action)

  // log all changes to redux state
  logger.debug({
    message: 'Redux state changed',
    data: {action, state: rootState, previousState: state}
  })

  return rootState
}

export function getRootState() {
  return rootState
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware().concat([guestWebKeysApi.middleware])
  },
  devTools: reduxDevTools
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

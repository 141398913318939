import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {getConfig} from './config'

export interface OpenDoorParams {
  webKeyId: string
  doorId: string
}

const {webKeysApiUrl} = getConfig()

export const guestWebKeysApi = createApi({
  reducerPath: 'guestWebKeysApi',
  baseQuery: fetchBaseQuery({
    baseUrl: webKeysApiUrl
  }),
  tagTypes: ['DoorList', 'ReservationWebKeys'],
  endpoints: builder => ({
    getReservationWebKeys: builder.query({
      query(webKeyId: string) {
        return `/reservation-webkeys?webKeyId=${webKeyId}`
      },
      providesTags: ['ReservationWebKeys']
    }),
    openDoor: builder.mutation({
      query({webKeyId, doorId}: OpenDoorParams) {
        return {
          url: `/doors/${doorId}/open?webKeyId=${webKeyId}`,
          method: 'POST'
        }
      },
      invalidatesTags: []
    })
  })
})

export const {
  useGetReservationWebKeysQuery,
  useOpenDoorMutation
} = guestWebKeysApi

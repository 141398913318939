import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons'

export interface ErrorViewProps {
  error?: string
}

export default function ErrorView({error}: ErrorViewProps) {
  return (
    <div className="flex flex-col justify-center items-center flex-grow">
      <div className="flex flex-col justify-center items-center bg-error rounded-full h-32 w-32">
        <FontAwesomeIcon
          icon={faExclamationCircle}
          className="text-7xl text-white"
        />
      </div>
      <h1 className="text-center header text-4xl mt-8">An error occurred.</h1>
      <div className="text-xl my-2">{error}</div>
      <p className="text-center text-xl leading-tight mt-8">
        Please see Scout Living Staff <br />
        on the second floor.
      </p>
    </div>
  )
}

import React, {useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faInfo} from '@fortawesome/free-solid-svg-icons'
import WebKeyItem from './WebKeyItem'
import WebKeyModal from './WebKeyModal'
import {Door, DoorList} from '../../types'

export interface WebKeyViewProps {}

interface ViewState {
  activeDoor: Door
  isModalOpen: boolean
}

export interface WebKeyViewProps {
  webKeyId: string
  doors: DoorList
  checkinUrl?: string
}

export default function WebKeyView({
  webKeyId,
  doors,
  checkinUrl
}: WebKeyViewProps) {
  const [appState, setAppState] = useState<ViewState>({
    activeDoor: null,
    isModalOpen: false
  })

  const featuredDoors = doors?.featured || []
  const commonDoors = doors?.common || []

  return (
    <section className="flex grow">
      <div className="w-full flex flex-col">
        <div className="m-4">
          <div className="flex flex-row items-center">
            <p className="text-md flex-1 leading-5">
              You will use these digital keys to open all doors at Scout Living.
              Locate the door you wish to open and tap the corresponding button
              to unlock.
            </p>
            <div className="flex-none dropdown dropdown-end ml-4">
              <button
                tabIndex={0}
                role="button"
                className="bg-base-100 text-white rounded-full h-6 w-6 flex justify-center items-center cursor-pointer"
              >
                <FontAwesomeIcon icon={faInfo} className="text-sm" />
              </button>
              <div
                tabIndex={0}
                className="dropdown-content bg-white rounded-box z-[1] w-96 p-8 shadow-xl text-sm"
              >
                Please be aware that sharing digital keys with third parties not
                staying in your room is strictly prohibited. These keys are
                intended for use by Scout Living guests only. Sharing your
                digital keys with non-guests can lead to security breaches,
                unauthorized access, and potential misuse of our facilities. In
                accordance with our security policies, Scout Living, powered by
                Placemakr reserves the right in its sole discretion to revoke
                any digital key without prior notice if it is found to be shared
                or misused. Revoked keys will immediately lose their access
                privileges, and you may be required to request a new key.
              </div>
            </div>
          </div>
        </div>

        <hr className="mx-4 border-base-100" />

        <div className="m-4 my-6 flex flex-col gap-8">
          {featuredDoors.map((door: Door) => {
            return (
              <WebKeyItem
                key={door.id}
                door={door}
                isPrimary={true}
                onOpen={() => {
                  setAppState({
                    ...appState,
                    activeDoor: door,
                    isModalOpen: true
                  })
                }}
              />
            )
          })}
        </div>

        <hr className="mx-4 border-base-100 flex-none" />

        {/* Note: setting an explicit height allows this to "grow" properly */}
        <div
          className={
            'mx-4 mt-6 pb-4 h-32 grow flex flex-col gap-8 overflow-y-scroll ' +
            'bg-gradient-to-b from-transparent from-95% to-gray-100'
          }
        >
          {commonDoors.map((door: Door) => {
            return (
              <WebKeyItem
                key={door.id}
                door={door}
                isPrimary={false}
                onOpen={() => {
                  setAppState({
                    ...appState,
                    activeDoor: door,
                    isModalOpen: true
                  })
                }}
              />
            )
          })}
        </div>

        {checkinUrl ? (
          <>
            <hr className="mx-4 border-base-100 h-2" />
            <div className="m-4 mb-8 text-sm">
              Looking for information about the property and amenities? Check
              out your{' '}
              <a
                className="link link-primary font-bold"
                target="_blank"
                href={checkinUrl}
              >
                check-in page.
              </a>
            </div>
          </>
        ) : (
          ''
        )}
      </div>

      <WebKeyModal
        webKeyId={webKeyId}
        door={appState.activeDoor}
        isOpen={appState.isModalOpen}
        onClose={() => {
          setAppState({
            activeDoor: null,
            isModalOpen: false
          })
        }}
      />
    </section>
  )
}

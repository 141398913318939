import {LogLevel} from '../types'

declare var process: {
  env: {
    [key: string]: string
  }
}

export interface AppConfig {
  environment: string
  loggingApiUrl: string
  loggingEnabled: boolean
  loggingLevel: LogLevel
  metricsApiUrl: string
  metricsEnabled: boolean
  metricsNamespace: string
  reduxDevTools: boolean
  webKeysApiUrl: string
}

export function getConfig(): AppConfig {
  return {
    environment: process.env.ENVIRONMENT,
    loggingApiUrl: process.env.LOGGING_API_URL,
    loggingEnabled: process.env.LOGGING_ENABLED === 'true',
    loggingLevel: process.env.LOGGING_LEVEL as LogLevel,
    metricsApiUrl: process.env.METRICS_API_URL,
    metricsEnabled: process.env.METRICS_ENABLED === 'true',
    metricsNamespace: process.env.METRICS_NAMESPACE,
    reduxDevTools: process.env.REDUX_DEV_TOOLS === 'true',
    webKeysApiUrl: process.env.WEBKEYS_API_URL
  }
}

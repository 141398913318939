import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDoorOpen} from '@fortawesome/free-solid-svg-icons'

export default function CheckedOutView() {
  return (
    <div className="flex flex-col justify-center items-center flex-grow">
      <div className="flex flex-col justify-center items-center flex-grow">
        <div className="flex flex-col justify-center items-center bg-secondary rounded-full h-32 w-32">
          <FontAwesomeIcon icon={faDoorOpen} className="text-5xl text-white" />
        </div>
        <h1 className="text-center header text-4xl mt-8">
          You've been
          <br />
          checked out!
        </h1>
        <p className="text-center text-xl leading-tight mt-8">
          Safe travels and we hope to <br />
          see you again soon.
        </p>
      </div>
      <p className="text-center italic w-3/5 mb-8">
        If you believe this is an error, please see the front desk on the second
        floor.
      </p>
    </div>
  )
}

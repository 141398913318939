import React from 'react'
import {createRoot} from 'react-dom/client'
import App from './components/App'
import {Provider} from 'react-redux'
import AppErrorBoundary from './components/AppErrorBoundary'
import {store} from './store/store'
import './index.css'

const container = document.getElementById('appRoot')
const root = createRoot(container!)
root.render(
  <AppErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
  </AppErrorBoundary>
)

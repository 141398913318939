export enum LogLevel {
  DEBUG = 'DEBUG',
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR'
}

export enum ReservationStatus {
  Confirmed = 'Confirmed',
  InHouse = 'InHouse',
  CheckedOut = 'CheckedOut',
  Canceled = 'Canceled',
  NoShow = 'NoShow'
}

export interface Reservation {
  propertyId: string
  reservationId: string
  roomNumber: string
  status?: ReservationStatus
  isAccessRevoked?: boolean
  checkinUrl?: string
}

export enum DoorGroup {
  Building = 'Building',
  Unit = 'Unit',
  Common = 'Common'
}

export interface Door {
  id: string
  name: string
  description?: string
  group: string
}

export interface DoorList {
  featured: Door[]
  common: Door[]
}

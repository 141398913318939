import clsx from 'clsx'
import React from 'react'
import {Door} from '../../types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLock} from '@fortawesome/free-solid-svg-icons'

export interface WebKeyItemProps {
  door: Door
  isPrimary: boolean
  onOpen: () => void
}

export default function WebKeyItem({door, isPrimary, onOpen}: WebKeyItemProps) {
  const {name, description} = door

  return (
    <div className="flex flex-row mr-4 justify-center items-center gap-1">
      <div className="grow">
        <h2 className="text-lg header leading-5">{name}</h2>
        <div className="text-sm text-neutral leading-4 mt-0.5">
          {description || ''}
        </div>
      </div>
      <div className="grow-0">
        <button
          className={clsx('btn w-48 rounded-full', {
            'btn-primary': !isPrimary,
            'btn-secondary': isPrimary
          })}
          onClick={() => onOpen()}
        >
          <FontAwesomeIcon icon={faLock} className="mr-1" />
          <span className="text-md uppercase">unlock door</span>
        </button>
      </div>
    </div>
  )
}
